<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type == 'edit' ? $t(`cip.plat.app.broadcast.title.editHeadTitle`) : $t(`cip.plat.app.broadcast.title.addHeadTitle`)"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {update, getDetail} from "@/api/system/broadcast";
import {mapGetters} from "vuex";


export default {
  name: "broadcastEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {

    return {
      type: 'view',
      dataForm: {
        "rdn": "0",
        "rcn": "1",
        "reg": 2,
        "sync": false,
        "queue": false,
        "duration": 999,
        "times": 1,
        "gap": 1,
        "prompt": false
      },
    }
  },
  computed: {
    ...mapGetters(["language"]),
    headBtnOptions() {

      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.app.broadcast.field.url'),
          prop: 'url',
          span: 8,
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.broadcast.field.url'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.app.broadcast.field.address'),
          prop: 'address',
          span: 8,
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.broadcast.field.address'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.app.broadcast.field.vcn'),
          prop: 'vcn',
          span: 8,
          type: 'select',
          dataType: "string",
          dicUrl: `${baseUrl}broadcast_vcn`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          value: 'xiaofeng',
        },
        {
          label: this.$t('cip.plat.app.broadcast.field.speed'),
          prop: 'speed',
          span: 8,
          type: "slider",
          value: 2,
          showInput: true,
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.broadcast.field.speed'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.app.broadcast.field.volume'),
          prop: 'volume',
          span: 8,
          type: "slider",
          value: 50,
          showInput: true,
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.broadcast.field.volume'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.app.broadcast.field.isOpen'),
          prop: 'isOpen',
          span: 8,
          type: 'switch',
          dataType: "string",
          dicUrl: `${baseUrl}is_enable`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          value: 'y',
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$loading()
          update({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close()
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
